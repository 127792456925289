import React from "react"

import { ArrowRightIcon } from "@heroicons/react/outline"
import { LinkButton } from "../primitives/LinkButton"
import { OutlineLinkButton } from "../primitives"

type CTAButtonProps = {
  label: string
}

const CTAButton: React.FC<CTAButtonProps> = ({ label }) => (
  <a
    href="mailto:sbarber@tektondigital.com"
    className={
      "inline-flex items-center justify-center px-5 py-3 border border-white text-base font-medium rounded-md text-white"
    }
  >
    {label} <ArrowRightIcon className="ml-2 h-6 w-6" />
  </a>
)

const SkewedRectangle: React.FC = () => (
  <svg
    className="hidden lg:block absolute right-0 inset-y-0 h-screen w-48 text-white transform xl:translate-x-1/2 2xl:translate-x-full"
    fill="currentColor"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    aria-hidden="true"
  >
    <polygon points="0,0 100,0 50,100 0,100" />
  </svg>
)

type Props = {
  title: React.ReactNode | string
  description: React.ReactNode | string
  cta: string
}

const HeroSection: React.FC<Props> = ({ title, description, cta }) => (
  <div
    className="h-screen bg-cover bg-center w-full flex"
    style={{
      backgroundImage:
        // 'url("https://media.istockphoto.com/photos/blue-and-pink-nebula-picture-id540717412?k=20&m=540717412&s=612x612&w=0&h=l7000eIYc0naEVn3r_DA-gHKd16EioY0aZGzsNM9Yak=")',
        'url("https://images.unsplash.com/photo-1538370965046-79c0d6907d47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80")'
        // 'url("https://images.unsplash.com/photo-1610296669228-602fa827fc1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1675&q=80")'
    }}
  >
    <div className="container mx-auto h-full flex-1">
      <div className="relative lg:max-w-5xl lg:w-full h-full z-10">
        <div className="relative p-4 flex flex-col justify-end h-full lg:pb-16">
          <h1 className="block text-white font-extrabold text-5xl lg:text-6xl">
            {title}
          </h1>
          <p className="mt-4 text-base text-gray-300 sm:text-lg max-w-xl md:text-xl">
            {description}
          </p>
          <div className="mt-4 flex lg:justify-start">
            <CTAButton label={cta} />
          </div>
        </div>
      </div>
    </div>
    <div className="absolute inset-0 bg-gradient-to-tr from-black"></div>
    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent"></div>
  </div>
)

const altImages = [
  "https://images.unsplash.com/photo-1449157291145-7efd050a4d0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
  "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
  "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
  "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80",
  "https://images.unsplash.com/photo-1506787497326-c2736dde1bef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=784&q=80",
  "https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
]

export default HeroSection
