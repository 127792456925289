import React from "react"
import { OutlineLinkButton } from "../primitives"

type Props = {
  title: React.ReactNode | string
  description: React.ReactNode | string
}

const CTASection: React.FC<Props> = ({ title, description }) => (
  <div className="grid items-center px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="mx-auto text-center">
      <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-5xl">
        {title}
      </h2>
      <div className="max-w-lg text-white mt-4">{description}</div>
      <div className="mt-8">
        <a
          href="mailto:sbarber@tektondigital.com"
          className={
            "inline-flex items-center justify-center px-5 py-3 border border-white text-base font-medium rounded-md text-white"
          }
        >
          Contact Us
        </a>
      </div>
    </div>
  </div>
)

export default CTASection
