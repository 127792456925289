import React from "react"

type Props = {
  title: React.ReactNode | string
  description: React.ReactNode | string
}

const HeadingSection: React.FC<Props> = ({ title, description, children }) => (
  <div className="grid items-center">
    <div className="max-w-5xl mx-auto px-4 md:px-0">
      <div className="lg:text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-5xl">
          {title}
        </h2>
        <p className="mt-4 max-w-3xl text-xl text-white lg:mx-auto">
          {description}
        </p>
        {children}
      </div>
    </div>
  </div>
)

export default HeadingSection
