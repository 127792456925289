import React from "react"

type FeatureProps = {
  title: React.ReactNode | string
  description: React.ReactNode | string
  icon?: React.ComponentType<any>
}

type Props = {
  title: React.ReactNode | string
  description: React.ReactNode | string
  features: FeatureProps[]
}

const FeatureSection: React.FC<Props> = ({ title, description, features }) => (
  <div className="px-6 md:px-0">
    <div className="lg:text-center">
      <h2 className="text-6xl leading-8 font-extrabold tracking-tight text-white">
        {title}
      </h2>
      <p className="mt-4 max-w-2xl text-xl text-white lg:mx-auto">
        {description}
      </p>
    </div>

    <div className="mt-10">
      <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        {features.map((feature, i) => (
          <div key={i} className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-black text-white">
                <feature.icon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-white">
                {feature.title}
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-white">
              {feature.description}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  </div>
)

export default FeatureSection
