import React, { ElementType } from "react"
import { classNames } from "../../utils/classNames"

type Props = {
  title: React.ReactNode | string
  description: React.ReactNode | string
  flip?: boolean
  image: React.ReactNode
  features: string[]
}

const ServiceSection: React.FC<Props> = ({
  title,
  description,
  flip,
  image,
  features,
}) => {
  return (
    <div
      className={classNames(
        "relative flex flex-col",
        flip ? "lg:flex-row" : "lg:flex-row-reverse"
      )}
    >
      <div
        className={classNames(
          "lg:absolute lg:inset-0",
          "grid grid-col-1 items-center w-full lg:w-1/2",
          flip ? "lg:right-auto" : "lg:left-auto"
        )}
      >
        {image}
      </div>
      <div className="container mx-auto">
        <div
          className={classNames(
            "grid items-center justify-center p-8 md:p-8 md:py-8 lg:px-0 lg:w-1/2",
            flip
              ? "lg:float-right lg:pl-16 xl:pl-24"
              : "lg:float-left lg:pr-16 xl:pr-24"
          )}
        >
          <div>
            <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-5xl">
              {title}
            </h2>
            <p className="mt-4 max-w-lg text-md text-white">{description}</p>
            <dl className="mt-8 space-y-4 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-4">
              {features.map((feature, i) => (
                <ul key={i} className="list-none">
                  {/* {feature.icon && (
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-transparent text-white">
                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                    )} */}
                  <li className="text-lg leading-6 font-medium text-white">
                    {feature}
                  </li>

                  {/* <dd className="mt-2 ml-16 text-base text-white max-w-lg">
                    {feature.description}
                  </dd> */}
                </ul>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceSection
