import React from "react"
import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline"

import SEO from "../components/Seo"

import { Placement } from "../components/primitives"
import Layout from "../components/layouts/Layout"

import CertificationSection from "../components/sections/CertificationSection"
import CTASection from "../components/sections/CTASection"
import FeatureSection from "../components/sections/FeatureSection"
import HeadingSection from "../components/sections/HeadingSection"
import HeroSection from "../components/sections/HeroSection"
import ServiceSection from "../components/sections/ServiceSection3"

import ServiceDesign from "../assets/service_design.svg"
import ServiceDevelop from "../assets/service_develop.svg"
import ServiceGrowth from "../assets/service_growth.svg"
import ServiceStrategy from "../assets/service_strategy.svg"

const Page = () => (
  <Layout>
    <SEO title="Home" />
    <Placement id="hero" bg="black" fullBleed className="">
      <HeroSection
        title={
          <>
            <span>
              <div className="mb-3">
                <span className="underline">Grow</span> your Business.
              </div>
              <div className="mb-3">
                <span className="underline">Increase</span> your Profits.
              </div>
              <div>
                <span className="underline">Delight</span> your Customers.
              </div>
            </span>
          </>
        }
        description={
          <span>
            With our team of designers, developers, & growth strategists, there
            is no limit to where we might go.
          </span>
        }
        cta="Let's get started"
      />
    </Placement>
    <Placement id="about" bg="black" className="py-16 lg:py-48">
      <HeadingSection
        title={
          <>
            We build businesses into brands
            <br />
            with <span className="underline">Strategy</span>,{" "}
            <span className="underline">Design</span>, &{" "}
            <span className="underline">Technology</span>.
          </>
        }
        description={
          <span>
            From improving existing websites & products, to crafting something
            entirely new.
            <br />
            Clients hire us to identify challenges, develop solutions, and help
            their ventures grow.
          </span>
        }
      />
    </Placement>
    <Placement id="services" bg="black" className="pt-16 pb-8 lg:pt-24">
      <FeatureSection title="Our Services" description="" features={[]} />
    </Placement>
    <Placement
      id="services-strategy"
      bg="black"
      fullBleed
      className="py-8 lg:pb-36"
    >
      <ServiceSection
        title="Strategy"
        flip
        description="It's dangerous to go alone. Bring our strategists along. We'll help you clarify your purpose, find your voice, understand your customers, and define your goals."
        image={<ServiceStrategy className="w-full h-auto" />}
        features={[
          "Business & Service Design",
          "Growth Channel Strategy",
          "Customer Segmentation & Analysis",
          "Experience Design",
          "Content Strategy",
          "Information Architecture",
          "User Research",
        ]}
      />
    </Placement>
    <Placement
      id="services-design"
      bg="black"
      fullBleed
      className="py-8 lg:py-36"
    >
      <ServiceSection
        title="Design"
        description="A business’ personality and values are expressed in their design. Let us build a an experience that resonates with your customers."
        image={<ServiceDesign className="w-full h-auto" />}
        features={[
          "Logo & Visual Identity System",
          "Voice & Tone",
          "Market Positioning",
          "Design System",
          "Content Design & Creation",
          "UI / UX Design",
        ]}
      />
    </Placement>
    <Placement
      id="services-develop"
      bg="black"
      fullBleed
      className="py-8 lg:py-36"
    >
      <ServiceSection
        title="Develop"
        flip
        description="We create software that empowers peoples lives. Our mad scientists combine cutting edge technology to make your idea a reality that radically changes your business for the better."
        image={<ServiceDevelop className="w-full h-auto" />}
        features={[
          "Prototyping",
          "Responsive Web Design",
          "App Development",
          "Mobile App Development",
          "CMS Implementation",
          "Application Architecture",
          "Cloud Infrastructure",
          "Database Design & Management",
          "Usability Testing",
        ]}
      />
    </Placement>
    <Placement
      id="services-growth"
      bg="black"
      fullBleed
      className="py-8 lg:py-36"
    >
      <ServiceSection
        title="Growth"
        description="Build it and they will come they said. Not so much. Our team of experts will help your audience find your brand and set it on a trajectory toward the stars."
        image={<ServiceGrowth className="w-full h-auto" />}
        features={[
          "User Anayltics",
          "Social Media Management",
          "Facebook & Instagram Advertising",
          "Email Marketing & Automation",
          "Conversion Optimization",
          "Funnel Design",
        ]}
      />
    </Placement>
    {/* 
    <Placement id="certification" bg="gray" className="py-16 lg:py-24">
      <CertificationSection
        title={
          <>
            Service Disabled Veteran <br /> Owned Small Business
          </>
        }
        description={
          <>
            We are a Service-Disabled Veteran-Owned Small Business
            <div className="mt-2">
              <a href="#">Download</a> our certification documents in PDF!
            </div>
          </>
        }
      />
    </Placement> */}
    <Placement id="contact" bg="black" className="lg:pb-16">
      <CTASection
        title="Ready to take off?"
        description={
          <span>
            {/* It's dangerous to go alone. Bring us, we're your experts!
            <br /> */}
            Let's take your business where no business has gone before.
          </span>
        }
      />
    </Placement>
  </Layout>
)

export default Page
